import React, { useState } from "react";
import { Modal, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import StarIcon from "../../assets/images/Star.svg";
import "../Profile/Styles/BookingStyles.css";

const DigitalResourcePurchaseModal = () => {
  const [isInitialModalOpen, setIsInitialModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedResource] = useState("Therapy Toolkit");
  const [price] = useState("₹500");
  const [authorName] = useState("Arjun Gupta");
  const [rating] = useState(4.5);

  const showInitialModal = () => {
    setIsInitialModalOpen(true);
  };

  const handleContinue = () => {
    setIsInitialModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showInitialModal}>
        Purchase Digital Resource
      </Button>

      {/* Initial Digital Resource Selection Modal */}
      <Modal
        title=""
        open={isInitialModalOpen}
        onCancel={() => setIsInitialModalOpen(false)}
        footer={null}
        centered
        width={600}
      >
        <div className="">
          {/* Header with Back Icon, Profile, and Rating */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex gap-4">
              <ArrowLeftOutlined
                className="text-lg"
                onClick={() => setIsInitialModalOpen(false)}
              />
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-gray-200"></div>{" "}
                {/* Placeholder for profile image */}
                <span className="font-semibold text-lg">{authorName}</span>
              </div>
            </div>
            <div className="flex items-center border border-black rounded-full px-4 py-2">
              <span className="text-sm mr-1">{rating}</span>
              <img src={StarIcon} alt="star" className="w-4 h-4" />
            </div>
          </div>

          {/* Resource Information */}
          <h3 className="text-xl font-bold mt-2 mb-1">{selectedResource}</h3>
          <p className="text-gray-500 mb-4">
            A 66 page toolkit for dissertation guidance
          </p>

          {/* Resource Image */}
          <div className="mb-4">
            <img
              src="https://i.ibb.co/Bs375bj/unsplash-f80d5-O78-Bmo.png"
              alt="Resource"
              className="w-full h-3/4 rounded-lg"
            />
          </div>

          {/* Description */}
          <h4 className="font-semibold mb-2">Description</h4>
          <p className="text-gray-600 mb-4">
            A 66 page toolkit that will give you the confidence to start your
            dissertation journey. This book is based on interactions with
            students doing their first dissertation.
          </p>

          {/* Testimonials */}
          <div className="flex space-x-4 overflow-x-auto mb-4">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg w-48">
                <p className="text-[12px]">
                  "Therapist's Name has been a wonderful guide during my
                  recovery."
                </p>
                <div className="flex items-center mt-2">
                  <div className="w-8 h-8 rounded-full bg-gray-300 mr-2"></div>{" "}
                  {/* Placeholder for user image */}
                  <div>
                    <p className="m-0 text-[12px] font-semibold">
                      Manjeet Kumar
                    </p>
                    <p className=" m-0 text-xs text-gray-500">21 Years</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Continue Button */}
          <button
            type="primary"
            className="w-full rounded-full py-2 bg-[#154134] text-white"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        title=""
        open={isConfirmationModalOpen}
        onCancel={handleConfirmationCancel}
        footer={null}
        centered
        width={600}
      >
        <div className="">
          {/* Header with Back Icon, Profile, and Rating */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex gap-4">
              <ArrowLeftOutlined
                className="text-lg"
                onClick={handleConfirmationCancel}
              />
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-gray-200"></div>{" "}
                {/* Placeholder for profile image */}
                <span className="font-semibold text-lg">{authorName}</span>
              </div>
            </div>
            <div className="flex items-center border border-black rounded-full px-4 py-2">
              <span className="text-sm mr-1">{rating}</span>
              <img src={StarIcon} alt="star" className="w-4 h-4" />
            </div>
          </div>

          {/* Resource Details */}
          <h3 className="text-xl font-bold mt-2 mb-1">{selectedResource}</h3>
          <p className="text-gray-500 mb-4">Digital Resource | {price}</p>

          {/* Order Summary */}
          <div className="w-full mb-4">
            <h4 className="font-bold">Order Summary</h4>
            <div className="bg-gray-100 p-4 rounded-lg flex justify-between">
              <span>{selectedResource}</span>
              <span>{price}</span>
            </div>
          </div>

          {/* Total Price and Confirm Button */}

          <div className="w-full flex justify-between items-center">
            <span className="text-md mr-3 py-2 px-4 rounded-full bg-[#EBEBE1]">
              ₹500
            </span>

            <button
              type="primary"
              className="bg-[#154134] py-2 text-white rounded-full w-full px-5 text-white"
            >
              Confirm and Pay
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DigitalResourcePurchaseModal;
