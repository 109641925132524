import Meta from "antd/es/card/Meta";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Card,
  Avatar,
  Typography,
  Space,
  Descriptions,
  Divider,
  Button,
} from "antd";
import { UserOutlined, DownloadOutlined } from "@ant-design/icons";
import BookingBg from "../assets/images/BookingBg.png";
import { useParams } from "react-router-dom";
import { getServiceRequestById } from "../Network/Payment";
import { fetchTherapistById } from "../Network/Therapist";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const { Title, Text } = Typography;
const razorPayStatusMap = {
  'captured': 'completed',
  'authorized': 'pending',
  'failed': 'failed',
  'refunded': 'refunded',
  'created': 'pending',
};

const paymentStatusMapInternal = {
  pending: 'Pending',
  completed: 'Completed',
  failed: 'Failed',
  refunded: 'Refunded',
  cash: 'Pending'
}
const OrderDetails = () => {
  const { id } = useParams();
  const [serviceRequest, setServiceRequest] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [therapistDetails, setTherapistDetails] = useState(null);
  const [therapistContactDetails, setTherapistContactDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    const FetchInfo = async () => {
      const response = await getServiceRequestById({
        serviceRequestId: id,
      });
      setServiceRequest(response.serviceRequest || null);
      setServiceDetails(response.serviceDetails || null);
      setPaymentDetails(response.paymentDetails || null);
      setTherapistContactDetails(
        response?.therapistContactDetails.phoneNumber.isdCode +
          response?.therapistContactDetails.phoneNumber.phone
      );

      const response1 = await fetchTherapistById(
        response.serviceRequest.therapistId
      );
      setTherapistDetails(response1);
    };
    FetchInfo();
  }, [id]);

  const getValue = (value) =>
    value !== undefined && value !== null ? value : "N/A";

  const handleDownloadPDF = () => {
    const cardElement = document.getElementById("order-details-card");
    html2canvas(cardElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`OrderDetails_${serviceRequest?._id || "unknown"}.pdf`);
    });
  };

  return (
    <div
      style={{
        backgroundImage: `url(${BookingBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full h-[105vh] flex items-center justify-center px-4 sm:px-0 overflow-scroll rounded-md"
    >
      <Card
        id="order-details-card"
        style={{ width: "100%", maxWidth: 600, margin: "auto" }}
        bodyStyle={{ paddingTop: "16px" }}
        className="rounded-md"
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          onClick={handleDownloadPDF}
          className="cursor-pointer"
        >
          <DownloadOutlined />
        </div>

        <Space direction="vertical" size="large" style={{ width: "100%", marginBottom: 25 }}>
          <Space>
            {therapistDetails?.profilePictureUrl?.s3Url ? (
              <img
                src={therapistDetails.profilePictureUrl.s3Url}
                className="h-10 w-10 rounded-full"
              />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <div>
              <a className="text-sm block text-black underline"
                 href={`${window.location.origin}/therapist/${therapistDetails?._id}`} target="_blank"
                 rel="noopener noreferrer">
                {getValue(
                  therapistDetails?.givenName +
                    " " +
                    therapistDetails?.familyName
                )}
              </a>
              <Text type="secondary">+{therapistContactDetails} </Text>
            </div>
          </Space>

          <Descriptions column={1} size="small">
            <Descriptions.Item label="Order Id">
              {getValue(serviceRequest?._id)}
            </Descriptions.Item>
            <Descriptions.Item label="Order Created">
              {getValue(
                moment(serviceRequest?.createdAt).format("YYYY-MM-DD hh:mm A"),
              )}
            </Descriptions.Item>
          </Descriptions>

          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              {/*<div style={{ display: "flex", justifyContent: "space-between" }}>*/}
              {/*  <Text>Therapist ID</Text>*/}
              {/*  <Text>{getValue(serviceRequest?.therapistId)}</Text>*/}
              {/*</div>*/}
              <Divider style={{ margin: "12px 0" }} />
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="small"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text>Total Amount</Text>
                  <Text>₹{getValue(serviceRequest?.originalPrice)}</Text>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text>Discount Applied</Text>
                  <Text>₹{getValue(serviceRequest?.totalDiscountApplied)}</Text>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text>Final Price</Text>
                  <Text>₹{getValue(serviceRequest?.finalPrice)}</Text>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text>Payment Status</Text>
                  {serviceRequest?.paymentMode === "cash" ? (
                    <Text>Pending</Text>
                  ) : (
                    <Text>{paymentStatusMapInternal[razorPayStatusMap[paymentDetails?.paymentStatus]]}</Text>
                  )}
                </div>
              </Space>
            </Space>
          </div>

          <div>
            <Title level={5}>Order Details</Title>
            <Descriptions column={1} size="small" bordered>
              <Descriptions.Item label="Customer">
                {serviceRequest?.clientName}
              </Descriptions.Item>
             {serviceRequest?.clientEmail && <Descriptions.Item label="Email">
                {serviceRequest?.clientEmail}
              </Descriptions.Item>}
              <Descriptions.Item label="Phone Number">
                {serviceRequest?.clientNumber?.isdCode} -{" "}
                {getValue(serviceRequest?.clientNumber?.phone)}
              </Descriptions.Item>
              <Descriptions.Item label="Service">
                {getValue(serviceDetails?.title)}
              </Descriptions.Item>
              {serviceRequest?.category === "appointment" && (
                <>
                  <Descriptions.Item label="Schedule">
                    {getValue(
                      moment(serviceRequest?.appointmentTime).format(
                        "DD-MM-YYYY hh:mm A"
                      )
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Mode">
                    {getValue(serviceRequest?.sessionType)}
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label="Payment Mode">
                {getValue(serviceRequest?.paymentMode)}
              </Descriptions.Item>
              {paymentDetails?.paymentMethod && <Descriptions.Item label="Payment Method">
                {paymentDetails?.paymentMethod}
              </Descriptions.Item>}
            </Descriptions>
          </div>
          {/*<div>*/}
          {/*  <Descriptions column={1} size="small">*/}
          {/*    <Descriptions.Item label="Cancellation/Modifications">*/}
          {/*      {`Please reach out to us on whatsapp at +${therapistContactDetails} directly for any queries.`}*/}
          {/*    </Descriptions.Item>*/}
          {/*  </Descriptions>*/}

          {/*</div>*/}
        </Space>
        <Meta title={therapistDetails?.businessName ? (therapistDetails.businessName) : null}
              avatar={
                therapistDetails?.logoUrl?.s3Url ? (
                  <Avatar src={therapistDetails.logoUrl.s3Url} />
                ) : null
              }
              description={
                <>
                  <div className="text-xs mb-1">
                    Note: Invoice doesn't reflect payment status of direct cash payments
                  </div>
                  {`Please WhatsApp on +${therapistContactDetails} for any queries.`}
                  <br/>
                  <div className="text-xs text-center mt-2">
                    <a href="https://pracflow.ai" target="_blank"
                       rel="noopener noreferrer">{`Powered by pracflow.ai`} </a>
                  </div>
                </>
              }
        />
      </Card>
    </div>
  );
};

export default OrderDetails;
