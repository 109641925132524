import React, { useEffect, useState } from "react";
import { Modal, Input, Radio, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { clientCookieName } from "../../constants";
import {EditOutlined } from "@ant-design/icons";
import { fetchUserDetails } from "../../Network/Client";
import { setAuthorization, setClientDetails } from "../../redux/combinedSlice";
import { createServiceRequest } from "../../Network/Payment";
import ProfilePicture from "../../assets/images/TherapistDummyImage.png";

const ConfirmationModal = ({ therapistDetails, selectedCard, price }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingMobile, setIsEditingMobile] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("online");

  const getCookie = (data) => {
    const cookies = document.cookie.split("; ");
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split("=")[1]);
      }
    });
    return decodedCookie;
  };

  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie("redirectTo");
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove("redirectTo", { path: "/" });
      }
    }

    const getUserDetails = async () => {
      try {
        const allDetails = await fetchUserDetails(authCookie);
        setName(allDetails?.givenName || "");
        setMobile(allDetails?.mobile || "");
        setEmail(allDetails?.email || "");
        dispatch(setClientDetails({ clientId: allDetails._id }));
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, [dispatch]);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const initiatePayment = async () => {
    try {
      const response = await createServiceRequest({
        therapistId: therapistDetails._id,
        category: selectedCard.category,
        serviceId: selectedCard._id,
        originalPrice: selectedCard.baseRate,
        finalPrice: price,
        paymentMode: paymentMethod,
        clientName: name,
        clientNumber: {
          isdCode: "+91",
          phone: mobile,
        },
        clientEmail: email,
      });
      return response;
    } catch (error) {
      console.error("Error creating service request:", error);
      message.error("Failed to create service request. Please try again.");
      return { success: false, error };
    }
  };

  const handleConfirm = async () => {
    // Validate name and mobile fields
    if (!name || !mobile) {
      message.error("Name and phone number are required.");
      return;
    }

    try {
      const response = await initiatePayment();
      if (response._id) {
        if (paymentMethod === "online") {
          navigate(`/payment/${response._id}`);
        } else if (paymentMethod === "cash") {
          navigate(`/watsappcheck/${response._id}`);
        }
      } else {
        message.error("Unable to process the request. Please check your details.");
      }
    } catch (error) {
      console.error("Error handling confirm action:", error);
    }
  };

  return (
    <div>
      <Modal
        title=""
        footer={null}
        centered
        width={600}
        className="sm:w-auto w-full rounded-lg"
        open={true}
      >
        <div className="p-4 sm:p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer mr-3 hover:bg-green-800 hover:text-white w-10 h-10 p-3 rounded-md"
                onClick={() => {
                  window.history.back();
                  window.location.reload();
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              <div className="flex items-center gap-2">
                <img
                  src={
                    therapistDetails?.profilePictureUrl?.cdnUrl ||
                    therapistDetails?.profilePictureUrl?.s3Url ||
                    ProfilePicture
                  }
                  className="w-10 h-10 rounded-full"
                  alt="Therapist Profile"
                />

                <span className="font-semibold text-base sm:text-lg">
                  {therapistDetails.givenName +
                    " " +
                    therapistDetails.familyName}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-4 w-full mb-4">
            <div className="flex items-center gap-1 relative">
              <Input
                value={name}
                placeholder="Enter your name"
                onChange={(e) => setName(e.target.value)}
                onBlur={() => setIsEditingName(false)}
                className="bg-gray-100 w-full rounded-lg py-2 px-4 text-sm sm:text-base"
              />

              <button
                className="text-gray-600 absolute right-3 top-3"
                onClick={() => setIsEditingName(true)}
              >
                <EditOutlined />
              </button>
            </div>

            <div className="flex items-center gap-1 relative">
              <Input
                value={mobile}
                placeholder="Enter your phone number"
                onChange={(e) => setMobile(e.target.value)}
                onBlur={() => setIsEditingMobile(false)}
                className="bg-gray-100 w-full rounded-lg py-2 px-4 text-sm sm:text-base"
              />

              <button
                className="text-gray-600 absolute right-3 top-3"
                onClick={() => setIsEditingMobile(true)}
              >
                <EditOutlined />
              </button>
            </div>
          </div>

          <h4 className="font-semibold text-sm sm:text-base mb-2">
            Order Summary
          </h4>
          <div className="flex items-center justify-between bg-gray-100 p-3 rounded-lg text-sm sm:text-base mb-6">
            <span>
              {(selectedCard.category === "resources" && "Get your Resource") ||
                (selectedCard.category === "workshop" && "Get your Workshop")}
            </span>
            <span className="flex">₹ {price}</span>
          </div>

          <div className="mb-4">
            <Radio.Group
              onChange={handlePaymentMethodChange}
              value={paymentMethod}
              className="w-full"
            >
              <div className="flex flex-col gap-2">
                <Radio value="online" className="p-3 rounded-lg">
                  Pay Online
                </Radio>
                <Radio value="cash" className="p-3 rounded-lg">
                  Cash on Delivery
                </Radio>
              </div>
            </Radio.Group>
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="bg-[#EBEBE1] text-black rounded-full py-2 px-6 text-sm sm:text-base flex w-24">
              ₹ {price}
            </div>
            <button
              type="button"
              className="bg-[#154134] text-white rounded-full py-2 px-6 text-sm sm:text-base w-[90%]"
              onClick={handleConfirm}
            >
              {paymentMethod === "online" ? "Confirm and Pay" : "Confirm"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
