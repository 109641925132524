import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const fetchConfigByTherapistId = async (therapistId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/availability/getConfigByTherapistId`,
    {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ therapistId: therapistId }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const fetchAvailOnDate = async ({
  therapistId,
  startDate,
  serviceId,
  selectedSessionLen,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/availability/getAvailabilityForUserOnDate`,
    {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        userId: therapistId,
        date: startDate,
        slotLength: selectedSessionLen,
        serviceId: serviceId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const fetchAvailDaysOfMonth = async ({
  therapistId,
  startDate,
  endDate,
  serviceId,
  selectedSessionLen,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/availability/getTherapistAvailabilityForDateRange`,
    {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        therapistId: therapistId,
        startDate: startDate,
        endDate: endDate,
        slotLength: selectedSessionLen,
        serviceId: serviceId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  const data = await response.json();
  return data; 
};

export { fetchConfigByTherapistId, fetchAvailOnDate, fetchAvailDaysOfMonth };
