import React, { useState } from "react";

// TEMP
import NavData from "../../../assets/data/TherapistData/NavBar.json";
import Templogo from "../../../assets/images/logoOriginalTransparent.png";

const Navbar = ({ therapistDetails }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { name, navigationLinks, socialMedia, ctaButtons } = NavData;
  const { primaryColor, secondaryColor, textColor, logoSize } = NavData.theme;
  const logo = therapistDetails?.logoUrl?.cdnUrl || therapistDetails?.logoUrl?.s3Url

  const styles = {
    navbar: `bg-transparent text-[${textColor}]`,
    navLink: `hover:text-[${secondaryColor}]`,
    button: `bg-[${primaryColor}] text-black decoration-none hover:bg-[${secondaryColor}] px-4 py-2 rounded`,
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsMenuOpen(false); // Close menu if on mobile
    }
  };

  return (
    <nav
      className={`${styles.navbar} poppins-regular p-4 flex justify-between items-center`}
    >
      {/* Logo */}
      <div className="flex items-center">
        <img
          src={logo}
          alt={`${name} logo`}
          className={logo ? `h-16 w-auto rounded-xl` : 'hidden'}
        />
      </div>

      {/* Navigation Links */}
      <div className="hidden md:flex space-x-6">
        {navigationLinks.map((link, idx) => (
          <button
            key={idx}
            onClick={() => scrollToSection(link.url)}
            className={`text-black text-md py-2 px-2 rounded-lg hover:text-black hover:bg-white ${styles.navLink}`}
          >
            {link.label}
          </button>
        ))}
      </div>

      {/* Call to Action Buttons */}
      <div className="poppins-regular hidden md:flex space-x-4">
        {ctaButtons.showBookSlot && (
          <a
            // href={`/therapist/booking/${therapistDetails._id}`}
            className={`rounded-full text-sm py-2 px-4 bg-[#154134] text-white ${styles.button}`}
            onClick={() => scrollToSection("Services")}
          >
            Book a Slot
          </a>
        )}
      </div>

      {/* Mobile Menu Button */}
      <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className="text-2xl">☰</span>
      </button>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-white shadow-md md:hidden">
          <div className="flex flex-col items-center py-4 space-y-4">
            {navigationLinks.map((link, idx) => (
              <button
                key={idx}
                onClick={() => scrollToSection(link.url)}
                className={`text-lg ${styles.navLink}`}
              >
                {link.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
