import React from "react";
import startNow from "../../assets/images/startNow.png";
import { useNavigate } from "react-router-dom";

const AboveFooterCTA = ({ therapistDetails }) => {
  const navigate = useNavigate();
  const handleSearchTherapists = () => {
    navigate("/search");
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      // setIsMenuOpen(false); // Close menu if on mobile
    }
  };
  return (
    <div className="bg-gray-100 mx-4 md:mx-12 md:my-10  py-8 px-8 rounded-xl flex flex-col md:flex-row items-center justify-between">
      <div className="w-full md:w-1/3 mb-8 md:mb-0">
        <img
          src={
            therapistDetails.footerPhotoUrl?.cdnUrl ||
            therapistDetails.footerPhotoUrl?.s3Url ||
            startNow
          }
          alt="Call to action"
          className="rounded-3xl w-full"
        />
      </div>
      <div className="w-full md:w-2/3 px-8 flex flex-col justify-center-">
        <h2 className="text-3xl md:text-4xl font-bold leading-normal libre-baskerville-bold mb-4">
          {therapistDetails.footerHeadline}
        </h2>
        <h5 className="text-lg text-left text-gray-600 mb-6">
          {therapistDetails.footerDescription}
        </h5>
        <div className="flex flex-col md:flex-row justify-center md:justify-start gap-4">
          <button
            onClick={() => scrollToSection("Services")}
            className="bg-black  border-2 border-black text-white py-3 px-6 rounded-full font-semibold hover:bg-transparent hover:text-black hover:border-black transition"
          >
            Get Started
          </button>
          <a
            onClick={() => scrollToSection("Footer")}
            className="bg-transparen text-center text-black border-2 border-black py-3 px-6 rounded-full font-semibold hover:bg-black hover:text-white  transition"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboveFooterCTA;
