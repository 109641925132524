import axios from "axios";
import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const getServicesForClients = async (therapistId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/availability/getServiceForClients`,
      { therapistId: therapistId },
      { withCredentials: true } // Add this if you need to include cookies
    );

    return response.data; // Axios automatically parses JSON responses
  } catch (error) {
    console.error("Error fetching service data:", error);
    // Handle the error as needed, e.g., by calling a custom error handler
    await handleApiErrors(error.response);
    return null;
  }
};
const getPackagesByid = async (therapistId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/availability/getPackagesForClients`,
      { therapistId: therapistId },
      { withCredentials: true } // Add this if you need to include cookies
    );

    return response.data; // Axios automatically parses JSON responses
  } catch (error) {
    console.error("Error fetching service data:", error);
    // Handle the error as needed, e.g., by calling a custom error handler
    await handleApiErrors(error.response);
    return null;
  }
};

export { getServicesForClients, getPackagesByid };
