import React from 'react';
import startNow from '../assets/images/startNow.png';
import { useNavigate } from 'react-router-dom';

const AboveFooterCTA = () => {
  const navigate = useNavigate();
  const handleSearchTherapists = () => {
    navigate('/search');
  };
  
  return (
    <div className='bg-gray-100 mx-4 md:mx-12 md:my-10 my-20 py-8 px-8 rounded-xl flex flex-col md:flex-row items-center justify-between'>
      <div className='w-full md:w-1/2 mb-8 md:mb-0'>
        <img
          src={startNow}
          alt='Call to action'
          className='rounded-xl w-full md:w-2/3'
        />
      </div>
      <div className='w-full md:w-1/2 flex flex-col justify-center-'>
        <h2 className='text-3xl md:text-4xl font-bold leading-normal libre-baskerville-bold mb-4'>
          Ready to Begin Your Journey?
        </h2>
        <h5 className='text-lg text-left text-gray-600 mb-6'>
          Whether you're seeking therapy, counseling, or just need a bit of
          guidance, we are here to help. Start your journey towards a better you
          today.
        </h5>
        <div className='flex flex-col md:flex-row justify-center md:justify-start gap-4'>
          <button
            onClick={handleSearchTherapists}
            className='bg-black  border-2 border-black text-white py-3 px-6 rounded-full font-semibold hover:bg-transparent hover:text-black hover:border-black transition'
          >
            Get Started
          </button>
          <a
            href='#footer'
            className='bg-transparen text-center text-black border-2 border-black py-3 px-6 rounded-full font-semibold hover:bg-black hover:text-white  transition'
          >
            Contact Us
        </a>
        </div>
      </div>
    </div>
  );
};

export default AboveFooterCTA;
