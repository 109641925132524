import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyPayment,
  handleFailedPayment,
  getServiceRequestById,
} from "../Network/Payment";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  UserOutlined,
  AppstoreOutlined,
  FontSizeOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { setBookingDetails } from "../redux/combinedSlice";
import { handleError } from "../ErrorHandling/handleError";
import bookingImage from "../assets/images/bookingImage.png";

function Payment() {
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentRetries, setPaymentRetries] = useState(0);
  const [prefill, setPrefill] = useState();
  const [timeLeft, setTimeLeft] = useState(30);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const navigate = useNavigate();
  const { serviceid } = useParams();
  const [id, setId] = useState("shdhdh");
  const dispatch = useDispatch();
  const [serviceRequest, setServiceRequest] = useState("");
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  useEffect(() => {
    const FetchInfo = async () => {
      const response = await getServiceRequestById({
        serviceRequestId: serviceid,
      });
      setServiceRequest(response);
      setId(response?.serviceRequest.therapistId);
    };
    FetchInfo();
  }, []);
  const addNotification = (type, message) => {
    let title, icon;

    switch (type) {
      case "success":
        title = "Success";
        icon = <CheckCircleOutlined style={{ color: "#52c41a" }} />;
        break;
      case "failure":
        title = "Failure";
        icon = <CloseCircleOutlined style={{ color: "#f5222d" }} />;
        break;
      case "alert":
        title = "Alert";
        icon = <ExclamationCircleOutlined style={{ color: "#faad14" }} />;
        break;
      default:
        title = "Notification";
        icon = null;
        break;
    }

    notification.open({
      message: title,
      description: message,
      icon: icon,
      duration: 3,
    });
  };

  useEffect(() => {
    if (isPaymentProcessing && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, isPaymentProcessing]);

  const pollPaymentStatus = async (paymentData, id) => {
    const intervalId = setInterval(async () => {
      try {
        const appointmentDetails = await verifyPayment(paymentData);
        if (
          appointmentDetails.paymentDetails?.paymentStatus === "captured" &&
          appointmentDetails.paymentDetails?.captured === true
        ) {
          addNotification("success", "Payment captured. Booking confirmed.");
          clearInterval(intervalId);
          //dispatch(setBookingDetails({}));
          navigate(`/watsappcheck/${appointmentDetails.serviceRequest._id}`);
        } else if (appointmentDetails.paymentDetails?.paymentStatus === "failed") {
          clearInterval(intervalId);
          addNotification("failure", "Payment failed. Please try again.");
          const { errorCode, errorDescription, errorReason } =
            appointmentDetails.paymentDetails;
          console.error(
            `Razorpay Error: ${errorCode} - ${errorDescription} - ${errorReason}`
          );
          addNotification("failure", `Payment failed: ${errorDescription}`);
          await handleFailedPayments({
            appointmentId: appointmentDetails._id,
            paymentId: appointmentDetails.paymentDetails.paymentId,
            orderId: appointmentDetails.serviceRequest.orderId,
            errorDescription: errorDescription,
          });
        } else {
          setPaymentRetries((prev) => prev + 1);
          if (paymentRetries >= 3) {
            clearInterval(intervalId);
            addNotification(
              "alert",
              "Payment processing failed! Sorry, we couldn't verify your payment. We promise, we will refund any" +
              " amount deducted within 7 days"
            );
            setIsPaymentProcessing(false);
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        addNotification("failure", "Error processing payment.");
        await handleError(error, navigate, dispatch);
      }
    }, 30000);
  };

  const handleFailedPayments = async (paymentData) => {
    try {
      const res = await handleFailedPayment(paymentData, authorization);
    } catch (error) {
      console.error("Failed to update draft appointment:", error);
    }
  };

  const initiatePayment = async (draftAppointment) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      currency: "INR",
      order_id: draftAppointment?.serviceRequest?.orderId,
      name: "Kasi.Care",
      description: draftAppointment?.serviceRequest?.category,
      prefill: prefill,

      handler: async function (response) {
        const paymentData = {
          serviceRequestId: draftAppointment.serviceRequest._id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
        };

        try {
          const verificationResponse = await verifyPayment(paymentData);
          if ( verificationResponse.paymentDetails?.paymentStatus === "captured" &&
            verificationResponse.paymentDetails?.captured === true
          ) {
            navigate(`/watsappcheck/${verificationResponse.serviceRequest._id}`);

            addNotification("success", "Order confirmed");
            navigate(`/watsappcheck/${verificationResponse.serviceRequest._id}`);
            //dispatch(setBookingDetails({}));
          } else {
            addNotification(
              "alert",
              "Payment is still processing. Please wait."
            );
            pollPaymentStatus(paymentData, draftAppointment._id);
          }
        } catch (error) {
          console.log("erro");
          navigate(`/watsappcheck/${draftAppointment._id}`);
        }
      },
      modal: {
        ondismiss: () => {
          addNotification("alert", "Payment was canceled.");
          setIsPaymentProcessing(false);
        },
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      const { code, description, reason, metadata } = response.error;
      console.error(`Razorpay Error: ${code} - ${description} - ${reason}`);
      addNotification("failure", `Payment failed: ${description}`);
      setIsPaymentProcessing(false);
      setPaymentMethod("cash");
      rzp1.close();
      handleFailedPayments({
        appointmentId: draftAppointment._id,
        paymentId: metadata.payment_id,
        orderId: draftAppointment.order.orderId,
        errorDescription: description,
      });
    });

    rzp1.open();
  };

  const handleBookAppointment = async () => {
    if (paymentMethod) {
      try {
        setIsPaymentProcessing(true);

        addNotification("alert", "Initializing Payment");
        const serviceRequest = await getServiceRequestById({
          serviceRequestId: serviceid,
        });
        if (!serviceRequest) {
          setIsPaymentProcessing(false);
          return;
        }
        await initiatePayment(serviceRequest);
      } catch (error) {
        addNotification("failure", "Booking is not confirmed");
        setIsPaymentProcessing(false);
        await handleError(error, navigate, dispatch);
      }
    } else {
      addNotification("failure", "Please complete all required fields");
    }
  };

  return (
    <div className="flex flex-wrap-reverse h-full gap-0 lg:p-16 my-auto justify-center items-center">
      <img
        className="lg:h-[33rem] hidden lg:flex"
        src={bookingImage}
        alt="bookingImage"
      />
      <div className="w-max container mx-auto py-4 bg-white rounded-xl">
        <h1 className="text-2xl text-center p-2 font-bold">Pay Online</h1>
        {serviceRequest !== undefined ? (
          <div>
            <div className="flex flex-col gap-4 px-2 w-full pt-4 rounded-lg">
              <div className="flex justify-between">
                <p className="text-lg h-fit m-0 font-medium">
                  <UserOutlined /> Therapist
                </p>
                <p className="h-fit m-0 text-lg font-semibold">
                  {serviceRequest?.therapistContactDetails?.givenName +
                    " " +
                    serviceRequest?.therapistContactDetails?.familyName}
                </p>
              </div>
              <hr />
              <div className="flex justify-between">
                <p className="text-lg h-fit m-0 font-medium">
                  <AppstoreOutlined /> Category
                </p>

                <p className="h-fit m-0 text-lg font-semibold">
                  {serviceRequest?.serviceDetails?.category}
                </p>
              </div>
              <hr />
              {serviceRequest?.serviceDetails?.category == "appointment" && (
                <>
                  <div className="flex m-0 h-fit justify-between">
                    <p className="text-lg m-0 h-fit font-medium">
                      <ClockCircleOutlined />
                      {"  "} Duration
                    </p>
                    <p className="text-lg m-0 h-fit font-semibold">
                      {serviceRequest?.serviceDetails?.appointment?.duration}{" "}
                      mins
                    </p>
                  </div>
                  <hr />
                </>
              )}
              {serviceRequest?.serviceDetails?.category == "appointment" && (
                <>
                  {" "}
                  <div className="flex justify-between">
                    <p className="text-lg m-0 h-fit font-medium">Type</p>
                    <p className="text-lg m-0 h-fit font-semibold">
                      {serviceRequest?.serviceDetails?.category}
                    </p>
                  </div>
                  <hr />
                </>
              )}
              <div className="flex justify-between">
                <p className="text-lg m-0 h-fit font-medium">
                  <FontSizeOutlined /> Title
                </p>

                <p className="text-lg m-0 h-fit font-semibold">
                  {serviceRequest?.serviceDetails?.title}
                </p>
              </div>
              <hr />
              {serviceRequest?.serviceDetails?.category == "appointment" && (
                <>
                  {" "}
                  <div className="flex justify-between">
                    <p className="text-lg m-0 h-fit font-medium">Schedule</p>
                    <p className="text-lg m-0 h-fit font-semibold">
                      {moment(serviceRequest?.serviceRequest?.appointmentTime).format('hh:mm A DD-MM-YYYY')}
                    </p>
                  </div>
                  <hr />
                </>
              )}
              <div className="flex justify-between">
                <p className="text-lg m-0 h-fit font-medium">
                  <MoneyCollectOutlined /> Amount Due
                </p>
                <p className="text-lg m-0 h-fit font-semibold">
                  {serviceRequest?.serviceRequest?.finalPrice} INR
                </p>
              </div>
              <hr />
              <div className="flex gap-8">
                <input
                  className="border-2 border-slate-200 pr pl-4 rounded-lg py-2"
                  type="text"
                  placeholder="Discount Code"
                />
                <button className="discount px-6 py-2 rounded-lg text-white bg-blue-800 h-fit">
                  Apply
                </button>
              </div>
              <hr />
              <button
                className="bg-slate-800 border-2 text-lg rounded-lg text-white font-semibold w-full hover:text-white duration-300 p-3"
                onClick={handleBookAppointment}
                disabled={isPaymentProcessing}
              >
                {isPaymentProcessing
                  ? `Processing... `
                  : paymentMethod === "cash"
                  ? "Confirm Appointment"
                  : `Pay ${serviceRequest?.serviceRequest?.finalPrice} INR`}
              </button>
              {isPaymentProcessing && (
                <div className="mt-2">
                  <p className="font-bold p-2 text-center bg-red-200 border-red-400 rounded-lg border-2">
                    Payment is processing, please wait... Don't close this
                    window
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-4">
            <p>No Appointment details available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
