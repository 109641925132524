import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./app.css";
import LandingPage from "./pages/Landingpage";
import Profile from "./pages/Profile";
import TherapistDetails from "./pages/TherapistDetails";
import PaymentPage from "./pages/PaymentPage";
import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import IntakeForm from "./pages/IntakeForm";
import Reschedule from "./pages/Reschedule";
import MyTherapist from "./pages/MyTherapist";
import MyResponses from "./pages/MyResponses";
import Conversations from "./pages/Conversations";
import Appointments from "./pages/Appointments";
import AboutUs from "./pages/AboutUs";
import Payments from "./pages/Payments";
import Auth from "./pages/Auth";
import Resource from "./pages/Resource";
import FeedbackForm from "./pages/FeedbackForm";
import MyAssignments from "./pages/MyAssignments";
import ViewAssignment from "./pages/ViewAssignment";
import ViewSubmittedAssignment from "./pages/ViewSubmittedAssignment";
import QuestionnaireForm from "./pages/QuestionnaireForm";
import TherapistProfilePage from "./pages/TherapistProfile";
import BookingModal from "./components/Profile/TherapistBookingModal";
import DigitalResourcePurchaseModal from "./components/Profile/TherapistResourceModal";
import EventBookingModal from "./components/Profile/TherapistEventModal";
import Resources from "./components/Profile/TherapistResourcesIndi";
import Workshop from "./components/Profile/TherapistWorkshop";
import OrderDetails from "./pages/Checkout";
import PaymentPendingCard from "./components/WatsaapStatus";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/signup" element={<Auth />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/search" element={<Home />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/myTherapist" element={<MyTherapist />} />
        {/* <Route exact path='/payments' element={<Payments/>} /> */}
        <Route
          exact
          path="/myResponse/:therapistId/:clientId"
          element={<MyResponses />}
        />
        <Route
          exact
          path="/watsappcheck/:id"
          element={<PaymentPendingCard />}
        />
        {/* <Route
          exact
          path="/therapist/:therapistId"
          element={<TherapistDetails />}
        /> */}
        <Route
          exact
          path="/therapist/:therapistId"
          element={<TherapistProfilePage />}
        />
        <Route exact path="/therapist/booking/:id" element={<BookingModal />} />
        <Route exact path="/therapist/resources/:id" element={<Resources />} />
        <Route exact path="/therapist/workshop/:id" element={<Workshop />} />
        <Route exact path="/checkout/:id" element={<OrderDetails />} />
        <Route
          exact
          path="/therapist/resource"
          element={<DigitalResourcePurchaseModal />}
        />{" "}
        <Route exact path="/therapist/event" element={<EventBookingModal />} />
        <Route
          exact
          path="/conversations/:therapistId"
          element={<Conversations />}
        />
        <Route exact path="/reschedule/:id" element={<Reschedule />} />
        <Route exact path="/payment/:serviceid" element={<PaymentPage />} />
        <Route exact path="/intakeFrom/:formId" element={<IntakeForm />} />
        <Route path="/feedbackForm/:formId" element={<FeedbackForm />} />
        <Route exact path="/resource/:resourceId" element={<Resource />} />
        <Route exact path="/myAssignments" element={<MyAssignments />} />
        <Route
          exact
          path="/viewAssignment/:assignmentId"
          element={<ViewAssignment />}
        />
        <Route
          exact
          path="/viewSubmittedAssignment/:assignmentId"
          element={<ViewSubmittedAssignment />}
        />
        <Route
          exact
          path="/viewAssignment/:assignmentId/:formId"
          element={<QuestionnaireForm />}
        />
        <Route from="*" to="/" />
      </Routes>
    </div>
  );
}

export default App;
