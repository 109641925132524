import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Card, Button } from "antd";
import BookingBg from "../../assets/images/BookingBg.png";
import { useParams } from "react-router-dom";
import { getServicesForClients } from "../../Network/Service";
import ConfirmationModal from "./TherapistConfirmationModal";
import ResoucePNG from "../../assets/images/resources.png";
import ProfilePicture from "../../assets/images/TherapistDummyImage.png";

const Resources = ({ selectedCard, therapistDetails }) => {
  const { id } = useParams();
  const [price, setPrice] = useState(selectedCard.baseRate);
  const [showNextComponent, setShowNextComponent] = useState(false);
  console.log(selectedCard);
  const minRate = selectedCard.baseRate;
  const maxRate = selectedCard.maxRate;
  const step = 100;

  const stepValues = [];
  for (let value = minRate; value <= maxRate; value += step) {
    stepValues.push(value);
  }
  if (stepValues[stepValues.length - 1] !== maxRate) {
    stepValues.push(maxRate); // Include maxRate explicitly if not aligned
  }

  useEffect(() => {
    const fetchServiceById = async () => {
      await getServicesForClients(id);
    };
    fetchServiceById();
  }, [id]);

  const handleRangeChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    setPrice(inputValue);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    setPrice(inputValue);
  };

  const handleInputBlur = () => {
    let validatedPrice = Number(price);
    if (isNaN(validatedPrice) || validatedPrice < minRate) {
      validatedPrice = minRate;
    } else if (validatedPrice > maxRate) {
      validatedPrice = maxRate;
    }
    setPrice(validatedPrice);
  };

  const handleContinueClick = () => {
    setShowNextComponent(true);
  };

  const percentage = ((price - minRate) / (maxRate - minRate)) * 100;

  return (
    <div
      style={{
        backgroundImage: `url(${BookingBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full h-screen flex items-center justify-center px-4 sm:px-0 overflow-scroll"
    >
      <div className="max-w-2xl mx-auto bg-white rounded-2xl w-full overflow-hidden">
        {!showNextComponent ? (
          <>
            {/* Header */}
            <header className="flex items-center p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer mr-3 hover:bg-green-800 hover:text-white w-10 h-10 p-3 rounded-md"
                onClick={() => {
                  window.history.back();
                  window.location.reload();
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>

              <div className="flex-1 flex items-center ">
                <img
                  src={
                    therapistDetails?.profilePictureUrl?.cdnUrl ||
                    therapistDetails?.profilePictureUrl?.s3Url ||
                    ProfilePicture
                  }
                  alt="Therapist"
                  className="w-10 h-10 rounded-full"
                />

                <span className="text-lg font-medium ml-2">
                  {therapistDetails.givenName} {therapistDetails.familyName}
                </span>
                <div className="flex items-center ml-auto">
                  <span className="bg-green-800 text-white px-3 py-1 rounded-lg">
                    ₹
                    <input
                      type="number"
                      value={price}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      className="bg-transparent text-white border-none text-right outline-none opacity-90"
                      min={minRate}
                      max={maxRate}
                    />
                  </span>
                </div>
              </div>
            </header>

            {selectedCard.isFlexibleRate && (
              <div className="px-9 pb-4 relative">
                <div className="relative ">
                  <input
                    type="range"
                    min={minRate}
                    max={maxRate}
                    value={price}
                    onChange={handleRangeChange}
                    className="w-full range-input appearance-none h-2 bg-gray-200 rounded-full"
                    style={{
                      background: `linear-gradient(to right, 
                      #4CAF50 0%, 
                      #4CAF50 ${percentage}%, 
                      #E0E0E0 ${percentage}%, 
                      #E0E0E0 100%)`,
                    }}
                  />
                  <div
                    className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 bg-green-800 text-white text-sm px-2 py-1 rounded"
                    style={{
                      left: `calc(${percentage}% - 10px)`,
                    }}
                  >
                    ₹{price}
                  </div>
                  <div className="flex justify-between text-xs text-gray-500 mt-2">
                    {stepValues.map((value, index) => (
                      <span
                        key={index}
                        className={`${
                          Number(price) === value
                            ? "font-bold text-green-800"
                            : ""
                        }`}
                      >
                        ₹{value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <main className="p-4">
              <Card
                className="mb-6"
                bodyStyle={{ padding: 0 }}
                bordered={false}
              >
                <img
                  src={selectedCard.thumbnail || ResoucePNG}
                  alt="Placeholder"
                  className="w-full h-fit object-cover"
                />
              </Card>
              <Card title="Description" bordered={false}>
                <p className="text-gray-700">{selectedCard.description}</p>
              </Card>
            </main>

            <div className="p-4">
              <button
                className="w-full rounded-lg px-2 py-2 bg-green-800 text-white hover:bg-green-700"
                onClick={handleContinueClick}
              >
                Continue
              </button>
            </div>
          </>
        ) : (
          <ConfirmationModal
            price={price}
            selectedCard={selectedCard}
            therapistDetails={therapistDetails}
          />
        )}
      </div>
    </div>
  );
};

export default Resources;
