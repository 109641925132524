import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  XFilled,
  XOutlined
} from "@ant-design/icons";
import {Button, Modal} from "antd";
import PracflowLogo from "./../../../assets/images/PracflowLogo.svg";
import React, { useEffect, useState } from "react";
import { getPackagesByid, getServicesForClients } from "../../../Network/Service";
import { useParams } from "react-router-dom";

const Footer = ({ therapistDetails }) => {
  const  therapistId  = therapistDetails._id;
  const [services, setServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
  });

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await getServicesForClients(therapistId);
        const packagesResponse = await getPackagesByid(therapistId);
        const combinedData = [...serviceResponse, ...packagesResponse];
        setServices(combinedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [therapistId]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      // setIsMenuOpen(false); // Close menu if on mobile
    }
  };

  const PolicyModal = ({ title, content, isOpen, onClose }) => (
    <Modal
      width={1000}
      title={title}
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={<div style={{textAlign: 'center'}}>
        <Button variant="filled" style={{
          backgroundColor: '#154134',
          color: 'white',
          border: 'none',
        }} key="close" onClick={onClose}>
         <span className="text-white">ok</span>
        </Button>
      </div>}
    >
      {content?.split('\n').map((para, idx) => (
        <p className="text-sm text-gray-600" key={idx}>{para}</p>
      ))}
    </Modal>
  );

  return (
    <footer className="bg-[#154134] text-white py-10" id="Footer">
      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-4 justify-between px-7">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <h3 className="text-lg text-white font-semibold mb-4">Know More</h3>
          <ul>
            {["About", "Resources", "Reviews", "Contact", "FAQ"].map((item) => (
              <li key={item} className="mb-1">
                <a href="#" className="text-sm text-white hover:underline">
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Therapy Section */}
        <div className="w-full md:w-2/3 mb-6 md:mb-0">
          <h3 className="text-lg text-white font-semibold mb-4">Services</h3>
          <ul>
            {/* //here the title of services would be there and on click in go to services again */}
            {services?.map((item) => (
              <li
                key={item}
                className="mb-1"
                onClick={() => scrollToSection("Services")}
              >
                <a className="text-sm text-white hover:underline">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Contacts Section */}
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          {(therapistDetails?.email || therapistDetails.phoneNumber) && (
            <h3 className="text-lg  text-white font-semibold mb-4">Contacts</h3>)}
          {therapistDetails?.email && (
            <div className="w-full md:w-1/5 mb-4">
              <a href={`mailto:${therapistDetails?.email}`} className="text-white">{therapistDetails?.email}</a>
            </div>)}
          {therapistDetails?.phoneNumber && (<div className="flex items-center py-2">
            <PhoneOutlined className="w-6 h-6 "/>
            <span className="text-white">{`${therapistDetails?.phoneNumber?.isdCode}${therapistDetails?.phoneNumber?.phone}`}</span>
          </div>)}
          {(therapistDetails?.phoneNumber || therapistDetails?.socialLinks) && (
            <h3 className="text-lg  text-white font-semibold mb-4">Social Links</h3>)}
          <div className="flex items-center">
            {therapistDetails?.phoneNumber && (<a
              href={`https://api.whatsapp.com/send?phone=%2B${therapistDetails?.phoneNumber?.isdCode}${therapistDetails?.phoneNumber?.phone}&text=Hi,%20I%20want%20to%20know%20more`}
              className="w-10 h-10"
            >
              <WhatsAppOutlined style={{color: 'white', fontSize: "x-large"}}/>
            </a>)}
            {therapistDetails?.socialLinks?.instagram && (<a
              href={`${therapistDetails.socialLinks.instagram}`}
              className="w-10 h-10"
            >
              <InstagramOutlined style={{color: 'white', fontSize: "x-large"}}/>
            </a>)}
            {therapistDetails?.socialLinks?.facebook && (<a
              href={`${therapistDetails.socialLinks.facebook}`}
              className="w-10 h-10"
            >
              <FacebookOutlined style={{color: 'white', fontSize: "x-large"}}/>
            </a>)}
            {therapistDetails?.socialLinks?.linkedin && (<a
              href={`${therapistDetails.socialLinks.linkedin}`}
              className="w-10 h-10"
            >
              <LinkedinOutlined className="w-6 h-6" style={{color: 'white', fontSize: "x-large"}}/>
            </a>)}
            {therapistDetails?.socialLinks?.twitter && (<a
              href={`${therapistDetails.socialLinks.twitter}`}
              className="w-10 h-10"
            >
              <XOutlined className="w-6 h-6" style={{color: 'white', fontSize: "x-large"}}/>
            </a>)}
          </div>

        </div>

        <div className="w-full mb-6 md:mb-0">
          <div className="justify-right">
            <h3 className="text-lg text-white font-semibold mb-4">Policies</h3>
              {therapistDetails.cancellationPolicy && (
                <div><a
                  onClick={() => openModal('Cancellation Policy', therapistDetails.cancellationPolicy)}
                  className="text-white hover:underline"
                >Cancellation & Refund Policy
                </a></div>
              )}
              {therapistDetails.privacyPolicy && (
                <div><a
                  onClick={() => openModal('Privacy Policy', therapistDetails.privacyPolicy)}
                  className="text-white hover:underline"
                >Privacy Policy
                </a></div>
                  )}
              {therapistDetails.termsAndConditions && (
                <div><a
                  onClick={() => openModal('Terms and Conditions', therapistDetails.termsAndConditions)}
                  className="text-white hover:underline"
                >Terms and Conditions
                </a></div>
                  )}

              <PolicyModal
                title={modalContent.title}
                content={modalContent.content}
                isOpen={isModalOpen}
                onClose={closeModal}
              />
          </div></div>
        </div>
    </footer>
  );
};

export default Footer;
