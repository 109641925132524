import React from "react";
import LeftWhiteIcon from "../../assets/images/LeftWhiteArrow.svg";
//TEMP
import HeroData from "../../assets/data/TherapistData/HeroSection.json";
import TherapistPicture from "../../assets/images/TherapistDummyImage.png";
// console.log(HeroData);

const HeroSection = ({hero, theme, therapistDetails}) => {
  const {tagline, highlight, subheading, cta, image, services, stats} =
    HeroData.hero;
  // console.log({ tagline, highlight, subheading, cta, image, services });

  const {
    buttonTextColor,
    buttonBackgroundColor,
    highlightColor,
    fontFamilyPrimary,
    fontFamilySecondary,
  } = HeroData.theme;
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: "smooth", block: "start"});
      // setIsMenuOpen(false); // Close menu if on mobile
    }
  };
  return (
    <section className="flex flex-col lg:flex-row items-center justify-between  lg:py-24 pb-5">
      <div className="flex flex-col w-full justify-between items-center">
        {/* Left content: Tagline, Subheading, and CTA */}
        <div className="flex w-full flex-col lg:flex-row items-top justify-between">
          <div className="lg:w-1/2 px-4 lg:px-10">
            {/* Tagline with two different fonts */}
            <h2 className="lg:text-5xl text-4xl font-bold poppins-regular leading-snug text-[#154134]">
              {therapistDetails.profileHeading}
            </h2>
            <h3 className="lg:text-4xl text-3xl kalnia-normal mt-4 leading-relaxed text-[#154134] mb-10">
              {therapistDetails.profileSummary}
            </h3>


            {/* Subheading */}
            {/* <p className="w-full lg:w-9/12 lg:text-xl leading-8 lg:leading-8 text-lg text-gray-700 m-0 p-0 poppins-regular">
              {therapistDetails.profileSummary}
            </p> */}

            {/* CTA Button */}
            {cta && (
              <a
                className=" w-fit items-center gap-2 tracking-[1px] text-[14px] lg:text-[16px] poppins-regular mt-6 inline-block px-6 py-3 flex rounded-xl"
                style={{
                  backgroundColor: buttonBackgroundColor,
                  color: buttonTextColor,
                  fontFamily: fontFamilySecondary,
                }}
                onClick={() => scrollToSection("Services")}
              >
                {cta.text}
                <span>
                  {" "}
                  <img src={LeftWhiteIcon} alt="leftArrow"/>
                </span>
              </a>
            )}
          </div>

          {/* Right content: Image */}
          <div className="mt-8 px-4 lg:px-10 lg:mt-0 lg:w-[50%] w-full">
            <img
              className="h-full w-full rounded-3xl object-cover"
              src={
                therapistDetails.coverPhotoUrl?.cdnUrl ||
                therapistDetails.coverPhotoUrl?.s3Url ||
                TherapistPicture
              }
              alt="image"
            />{" "}
          </div>
        </div>

        {/* Services Offered Below */}
        {therapistDetails.specialty &&
          therapistDetails.specialty.length > 0 && (
            <div className="w-full mt-10 lg:mt-16 bg-transparent">
              <div className="flex flex-wrap gap-4  justify-start lg:justify-center items-start lg:items-center">
                {therapistDetails.specialty.map((service, idx) => (
                  <div
                    key={idx}
                    className="flex lg:self-center self-start px-4 rounded-full"
                  >
                    <span className="lg:text-xl text-lg px-2 text-green-400">
                      ✱
                    </span>
                    <span className="lg:text-xl text-lg poppins-regular text-gray-800">
                      {service}
                    </span>
                    <span className="lg:text-xl text-lg px-2 text-green-400">
                      ✱
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

        <div className="w-full mt-12">
          <div className="flex flex-wrap gap-4 justify-center">
            <div
              className="flex flex-col w-full ml-4 mr-4 lg:w-fit gap-2 items-center justify-center py-6 px-10 rounded-2xl bg-[#F6FBF8]">
              <p className="text-[36px] m-0 p-o kalnia-normal">
                {therapistDetails.totalClientServed}+
              </p>
              <p
                className="text-[18px] m-0 p-0"
                style={{fontFamily: fontFamilySecondary}}
              >
                Total Client Served{" "}
              </p>
            </div>
            <div
              className="flex flex-col w-full ml-4 mr-4 lg:w-fit gap-2 items-center justify-center py-6 px-10 rounded-2xl bg-[#F6FBF8]">
              <p className="text-[36px] m-0 p-o kalnia-normal">
                {therapistDetails.totalSessionsTaken}+
              </p>
              <p
                className="text-[18px] m-0 p-0"
                style={{fontFamily: fontFamilySecondary}}
              >
                Total Sessions Taken{" "}
              </p>
            </div>
            <div
              className="flex flex-col w-full ml-4 mr-4 lg:w-fit gap-2 items-center justify-center py-6 px-10 rounded-2xl bg-[#F6FBF8]">
              <p className="text-[36px] m-0 p-o kalnia-normal">
                {therapistDetails.rating}/5
              </p>
              <p
                className="text-[18px] m-0 p-0"
                style={{fontFamily: fontFamilySecondary}}
              >
                Ratings{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
