import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeftWhiteIcon from "../../assets/images/LeftWhiteArrow.svg";
import nothingFound from "../../assets/images/No_results_found_illustration_generated.jpg";
import PopularIcon from "../../assets/images/Popular.svg";
import { getServicesForClients, getPackagesByid } from "../../Network/Service";
import Resources from "./TherapistResourcesIndi";
import Workshop from "./TherapistWorkshop";
import BookingModal from "./TherapistBookingModal";

const ServicesSection = ({ therapistDetails }) => {
  const [filter, setFilter] = useState("All");
  const [services, setServices] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAppointments, setShowAppointments] = useState(false);
  const [showWorkshops, setShowWorkshops] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [fullDescription, setFullDescription] = useState("");

  const therapistId = therapistDetails._id;
  const navigate = useNavigate();

  const cardColors = [
    "#EBEBE1",
    "#D9EFFD",
    "#e3DBF5F2",
    "#F0F4C3",
    "#FFEBEE",
    "#D1C4E9",
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await getServicesForClients(therapistId);
        const packagesResponse = await getPackagesByid(therapistId);
        const combinedData = [...serviceResponse, ...packagesResponse];
        setServices(combinedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [therapistId]);

  const handleFilterClick = (item) => {
    setFilter(item.category);
    setShowFullDescription(false)
    setSelectedCard(item);
    setShowAppointments(item.category === "appointment");
    setShowWorkshops(item.category === "workshop");
    setShowResources(item.category === "resources");
  };

  const handleReadMore = (description) => {
    setFullDescription(description);
    setShowFullDescription(true);
  };

  const filteredServices = services.filter((service) => {
    if (filter === "All") return true;
    if (filter === "1:1 Sessions") return service.category === "appointment";
    if (filter === "Workshops") return service.category === "workshop";
    if (filter === "Digital Resources") return service.category === "resources";
    return false;
  });

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * cardColors.length);
    return cardColors[randomIndex];
  };

  return (
    <>
      <section
        className="pb-5 sm:py-12 lg:px-10 p-4"
        style={{ backgroundColor: "#ffffff" }}
        id="Services"
      >
        <div className="flex flex-col lg:flex-row justify-between items-center flex-wrap">
          <h2
            className="text-xl sm:text-2xl md:text-3xl font-semibold mb-8"
            style={{ color: "#333" }}
          >
            What are you looking for?
          </h2>

          <div className="flex flex-wrap justify-center gap-4 mb-8">
            {["All", "1:1 Sessions", "Workshops", "Digital Resources"].map(
              (item) => (
                <button
                  key={item}
                  className={`px-3 sm:px-4 py-2 rounded-full font-semibold ${
                    filter === item
                      ? "bg-black text-white"
                      : "bg-gray-50 text-black"
                  }`}
                  onClick={() => setFilter(item)}
                >
                  {item}
                </button>
              )
            )}
          </div>
        </div>

        <div className="flex flex-wrap justify-center md:justify-start gap-4">
          {filteredServices.length > 0 ? (filteredServices.map((service) => (
              <div
                key={service._id}
                className="px-4 py-4 rounded-3xl w-full sm:w-[300px] lg:w-[320px] text-center"
                style={{
                  backgroundColor: getRandomColor(),
                  color: service.textColor || "#000",
                }}
              >
                <div className="flex items-center justify-between mb-4 gap-2">
                <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm font-bold whitespace-nowrap">
                  ₹ {service.baseRate}
                </span>
                  <div className="flex gap-1">
                  <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm whitespace-nowrap">
                    {service.isFlexibleRate ? "Pay as you Wish" : "Fixed"}
                    <span>
                      <img src={PopularIcon} alt="popular" />
                    </span>
                  </span>
                    {service.category === "appointment" && (
                      <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm whitespace-nowrap">
                      {service.appointment.duration} minutes
                    </span>
                    )}
                  </div>
                </div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-start">
                  {service.title}
                </h3>

                <p className="mb-4 text-left text-sm sm:text-base">
                  {service.description}
                </p>
                <div className="flex items-center justify-between">
                  <button
                    className="flex items-center gap-2 p-2 px-4 bg-[#154134] rounded-full text-white font-semibold text-sm"
                    onClick={() => handleFilterClick(service)}
                  >
                    Continue
                    <span>
                    <img src={LeftWhiteIcon} alt="leftArrow" />
                  </span>
                  </button>
                </div>
              </div>
            ))):
            (
              <div
                className="px-4 py-4 rounded-3xl w-full sm:w-[250px] text-center"
                style={{
                  backgroundColor: '#47b1e3',
                  color: "#000",
                }}
              >
                <p className="font-bold text-gray-50">Nothing offered here yet!</p>
                <img src={nothingFound}></img>
              </div>
            )}
        </div>
      </section>

      {(showResources || showWorkshops || showAppointments) && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50"
          style={{ height: "100vh" }}
        >
          {showResources && (
            <Resources
              selectedCard={selectedCard}
              therapistDetails={therapistDetails}
            />
          )}
          {showAppointments && (
            <BookingModal
              selectedCard={selectedCard}
              therapistDetails={therapistDetails}
            />
          )}

          {showWorkshops && (
            <Workshop
              selectedCard={selectedCard}
              therapistDetails={therapistDetails}
            />
          )}
        </div>
      )}

      {showFullDescription && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50"
          style={{ height: "100vh" }}
        >
          <div
            className="px-4 py-4 rounded-3xl w-[90%] sm:w-[70%] lg:w-1/2 text-center "
            style={{
              backgroundColor: getRandomColor(),
              color: "#000",
            }}
          >
            <div className="flex items-center justify-between mb-4 gap-2">
              <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm font-bold whitespace-nowrap">
                ₹ {fullDescription.baseRate}
              </span>
              <div className="flex gap-1">
                <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm whitespace-nowrap">
                  {fullDescription.isFlexibleRate ? "Pay as you Wish" : "Fixed"}
                  <span>
                    <img src={PopularIcon} alt="popular" />
                  </span>
                </span>
                {fullDescription.category === "appointment" && (
                  <span className="flex items-center gap-2 bg-white py-1 px-3 rounded-full text-sm whitespace-nowrap">
                    {fullDescription.appointment.duration} minutes
                  </span>
                )}
              </div>
            </div>
            <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-start">
              {fullDescription.title}
            </h3>
            <p className="mb-4 text-left text-sm sm:text-base">
              {fullDescription?.description}
            </p>
            <div className="flex items-center justify-between">
              <button
                className="flex items-center gap-2 p-2 px-4 bg-[#154134] rounded-full text-white font-semibold text-sm"
                onClick={() => handleFilterClick(fullDescription)}
              >
                Continue
                <span>
                  <img src={LeftWhiteIcon} alt="leftArrow" />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServicesSection;
