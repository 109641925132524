import moment from "moment";
import React, { useRef, useEffect, useState } from "react";
import {Modal, Button, Select, Input, Spin, Radio, message, Checkbox} from "antd";
import dayjs from "dayjs";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditOutlined, LoadingOutlined, SmileTwoTone,
} from "@ant-design/icons";
import {
  fetchAvailDaysOfMonth,
  fetchAvailOnDate,
  fetchConfigByTherapistId,
} from "../../Network/Availability";
import StarIcon from "../../assets/images/Star.svg";
import "../Profile/Styles/BookingStyles.css";
import BookingBg from "../../assets/images/BookingBg.png";
import ProfilePicture from "../../assets/images/TherapistDummyImage.png";

import EditIcon from "../../assets/images/editIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleError } from "../../ErrorHandling/handleError";
import { fetchUserDetails } from "../../Network/Client";
import Cookies from "js-cookie";
import { setAuthorization, setClientDetails } from "../../redux/combinedSlice";
import { clientCookieName } from "../../constants";
import { fetchTherapistByHandle } from "../../Network/Therapist";
import { createServiceRequest } from "../../Network/Payment";

const { Option } = Select;

const BookingModal = ({ selectedCard, therapistDetails }) => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("online");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userDetails, setuserDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const scrollContainerRef = useRef(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [selectedTime, setSelectedTime] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [consent, setConsent] = useState(true);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingMobile, setIsEditingMobile] = useState(false);
  const getCookie = (data) => {
    const cookies = document.cookie.split("; ");
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split("=")[1]);
      }
    });
    return decodedCookie;
  };
  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie("redirectTo");
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove("redirectTo", { path: "/" });
      }
    }

    const getUserDetails = async () => {
      try {
        const allDetails = await fetchUserDetails(authCookie);
        setuserDetails(allDetails);
        setName(allDetails?.givenName);
        setMobile(allDetails?.mobile);
        dispatch(setClientDetails({ clientId: allDetails._id }));
      } catch (error) {
        console.log("error", error);
      }
    };

    getUserDetails();
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const isFormValid = selectedDate && selectedTime && name && mobile && consent;

  const handleOk = () => {
    if (isFormValid) {
      setIsModalOpen(false);
      setIsConfirmationModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let appointmentTime;

  if (selectedDate && selectedTime) {
    appointmentTime = selectedTime;
  }

  // console.log("date", selectedDate, "time", selectedTime);
  const initiatePayment = async () => {
    try {
      const payload = {
        therapistId: therapistDetails._id,
        category: selectedCard.category,
        serviceId: selectedCard._id,
        baseRate: selectedCard.baseRate,
        clientId: userDetails?._id,
        sessionType: "video",
        finalPrice: price,
        paymentMode: paymentMethod,
        appointmentTime,
        clientName: name,
        clientNumber: {
          isdCode: "+91",
          phone: mobile,
        },
        clientEmail: email,
        consent
      };

      const response = await createServiceRequest(payload);
      return response;
    } catch (error) {
      console.error("Error creating service request:", error);
      message.error("Failed to create service request. Please try again.");
      return { success: false, error };
    }
  };

  const handleConfirmationCancel = async () => {
    try {
      if (!name || !mobile) {
        message.error("Name and phone number are required.");
        return;
      }
      setConfirmLoader(true);
      const response = await initiatePayment();
      setConfirmLoader(true);
      if (response._id) {
        // console.log("focus");
        if (paymentMethod === "online") {
          navigate(`/payment/${response._id}`);
        } else if (paymentMethod === "cash") {
          navigate(`/watsappcheck/${response._id}`);
        }
      } else {
        setConfirmLoader(false);
        message.error(
          "Unable to process the request. Please check your details."
        );
      }
    } catch (error) {
      setConfirmLoader(false);
      console.error("Error handling confirm action:", error);
    }
  };



  const handleTimeChange = (time) => {
    if (!time) return; 

    setSelectedTime(time);
  };
  const handleChange1 = () => {
    setIsConfirmationModalOpen(false);
    setIsModalOpen(true);
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const startDate = new Date().toISOString();
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 1);
  const formattedEndDate = endDate.toISOString();
  const [availableDate, setAvailabledate] = useState([]);
  const id = therapistDetails?._id;
  const formatDate = (dateString) => dayjs(dateString).format("ddd D MMM");
  const [Loader, setLoader] = useState(true);
  const [slotLoader, setSlotLoader] = useState(true);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const minRate = selectedCard.baseRate;
  const maxRate = selectedCard.maxRate;
  const stepValues = [];
  for (let value = minRate; value <= maxRate; value += 100) {
    stepValues.push(value);
  }
  if (stepValues[stepValues.length - 1] !== maxRate) {
    stepValues.push(maxRate);
  }
  useEffect(() => {
    const fetchAvailableDateofMonth = async () => {
      try {
        let commingdates = await fetchAvailDaysOfMonth({
          therapistId: id,
          startDate: startDate,
          endDate: formattedEndDate,
          serviceId: selectedCard?._id,
          selectedSessionLen: selectedCard?.appointment.duration,
        });
        setAvailabledate(commingdates);
        setSelectedDate(Object.keys(commingdates).find((key) => commingdates[key]));
        setLoader(false);
      } catch {
        console.log("error");
      }
    };

    fetchAvailableDateofMonth();
  }, []);
  const [availableTimeBydate, setAvailabletimebyDate] = useState([]);
  useEffect(() => {
    const getTimeSlots = async (date) => {
      try {
        setSlotLoader(true);
        const res = await fetchAvailOnDate({
          therapistId: id,
          startDate:
            new Date(selectedDate).toISOString() || new Date().toISOString(),
          serviceId: selectedCard?._id,
          selectedSessionLen: selectedCard?.appointment.duration,
        });
        const slotStartTimes = Object.values(res);
        setAvailabletimebyDate(slotStartTimes);
        setSlotLoader(false);
        // console.log("timeresponse", slotStartTimes);
      } catch (error) {
        setSlotLoader(false);
        console.log("err");
      }
    };
    getTimeSlots();
  }, [selectedDate]);
  const [price, setPrice] = useState(selectedCard.baseRate);

  const [visibleCount, setVisibleCount] = useState(8);
  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };
  const [showmode, setShowMode] = useState("Video ");
  const handleRangeChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    setPrice(inputValue);
  };
  const percentage = ((price - minRate) / (maxRate - minRate)) * 100;

  return (
    <div
      style={{
        backgroundImage: `url(${BookingBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full h-screen flex items-center justify-center px-4 sm:px-0"
    >
      {/* Initial Booking Modal */}
      {Loader ? (
        <div className="flex justify-center items-center mb-4">
          <Spin tip="Loading..." style={{ color: "green" }} />
        </div>
      ) : (
        <Modal
          title=""
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          centered
          width={600}
          className="sm:w-auto w-full"
        >
          <div className="poppins-regular rounded-3xl sm:p-6 p-4">
            <div className="flex sm:flex-row flex-row sm:justify-between  mb-4 items-center">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 cursor-pointer mr-3 hover:bg-green-800 hover:text-white w-10 h-10 p-3 rounded-md"
                  onClick={() => {
                    window.history.back();
                    window.location.reload();
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
                <div className="flex w-full gap-2 sm:justify-start justify-between items-center ">
                  <Select
                    defaultValue={selectedCard?.appointment.sessionType[0]}
                    className="sm:w-fit w-fit border-[1px] border-black rounded-full"
                    onChange={(value) => setShowMode(value)}
                  >
                    {/* {console.log(selectedCard.appointment)} */}
                    {selectedCard?.appointment.sessionType?.map((item) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="py-1 px-3 rounded-full bg-[#154134] text-white  sm:text-sm flex whitespace-nowrap">
                  {selectedCard?.appointment.duration} minutes
                </span>
                <span className="py-1 px-3 rounded-full bg-[#154134] text-white  sm:text-sm flex">
                  ₹{price}
                </span>
              </div>
            </div>
            {selectedCard.isFlexibleRate && (
              <div className="px-4 pb-4 relative">
                <div className="relative">
                  <input
                    type="range"
                    min={minRate}
                    max={maxRate}
                    value={price}
                    onChange={handleRangeChange}
                    className="w-full range-input appearance-none h-2 bg-gray-200 rounded-full"
                    style={{
                      background: `linear-gradient(to right, 
                      #154134 0%, 
                      #4CAF50 ${percentage}%, 
                      #E0E0E0 ${percentage}%, 
                      #E0E0E0 100%)`,
                    }}
                  />
                  <div
                    className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 bg-[#154134] text-white text-sm px-2 py-1 rounded"
                    style={{
                      left: `calc(${percentage}% - 10px)`,
                    }}
                  >
                    ₹{price}
                  </div>
                  <div className="flex justify-between text-xs text-gray-500 mt-2">
                    {stepValues.map((value, index) => (
                      <span
                        key={index}
                        className={`${
                          Number(price) === value
                            ? "font-bold text-[#154134]"
                            : ""
                        }`}
                      >
                        ₹{value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mb-4">
              <h3 className="my-2 p-0 font-normal text-left text-sm">
                Select Date
              </h3>
              <div className="flex justify-between items-center mx-2 sm:mx-4">
                <button onClick={scrollLeft} className="p-1">
                  <ArrowLeftOutlined/>
                </button>
                <div
                  ref={scrollContainerRef}
                  className="flex overflow-x-auto space-x-2 scrollbar-hide px-2 py-1"
                >
                  {Object.keys(availableDate)
                    .filter((dateString) => availableDate[dateString])
                    .map((dateString, i) => (
                      <div
                        key={i}
                        className={`px-2 py-1  sm:text-sm whitespace-nowrap cursor-pointer ${
                          selectedDate == dateString
                            ? "bg-[#154134] text-white rounded-lg"
                            : "bg-gray-100 text-gray-700 rounded-lg"
                        }`}
                        onClick={() => setSelectedDate(dateString)}
                      >
                        {formatDate(dateString)}
                      </div>
                    ))}
                </div>
                <button onClick={scrollRight} className="p-1">
                  <ArrowRightOutlined/>
                </button>
              </div>
            </div>

            {slotLoader ? (
              <div className="flex justify-center items-center mb-4">
                <Spin tip="Loading..." indicator={<LoadingOutlined spin/>}  size="large"/>
              </div>
            ) : (<div className="text-center mb-4">
              <h3 className="my-2 p-0 font-normal text-left text-sm">
                Select Time
              </h3>
              <div className="grid grid-cols-3 items-center justify-start gap-2">
                {availableTimeBydate
                  .filter((time) => time.isAvailable)
                  .slice(0, visibleCount)
                  .map((time) => (
                    <button
                      key={time.startTime}
                      type="button"
                      className={`w-full sm:w-auto  sm:text-sm font-semibold border-2 p-2 rounded-lg ${
                        selectedDate
                          ? selectedTime === time.startTime
                            ? "bg-[#154134] text-white border-[#154134] !important"
                            : "bg-gray-50 text-black border-gray-300"
                          : "bg-gray-100 text-gray-500 border-gray-300 cursor-not-allowed"
                      }`}
                      onClick={() =>
                        selectedDate && handleTimeChange(time?.startTime)
                      } // Call onClick only if a date is selected
                      disabled={!selectedDate}
                    >
                      {time.slotStart}
                    </button>
                  ))}
                {visibleCount <
                  availableTimeBydate.filter((time) => time.isAvailable)
                    .length && (
                    <Button
                      onClick={showMore}
                      className="w-full sm:w-auto text-xs sm:text-sm border-2 p-2"
                      disabled={!selectedDate} // Disable Show More until date selected
                    >
                      Show More
                    </Button>
                  )}
              </div>
            </div>)}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-4">
              <div>
                <label className="block mb-1 text-sm">Enter Name</label>
                <Input placeHolder="Enter Name" value={name} onChange={(e) => setName(e.target.value)}/>
              </div>
              <div>
                <label className="block mb-1 text-sm">Mobile Number</label>
                <Input
                  placeholder="Enter Mobile Number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-1 text-sm">Email</label>
                <Input
                  placeholder="Optional"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-3 text-sm">Consent</label>
                <Checkbox checked={consent} onChange={(e) => setConsent(e.target.checked)}>
                  I give my full consent to the t&c dictated by the therapist
                </Checkbox>
              </div>
            </div>

            <button
              type="primary"
              className={`w-full rounded-full py-2 text-white text-sm ${
                isFormValid
                  ? "bg-[#154134] cursor-pointer"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={handleOk}
              disabled={!isFormValid}
            >
              Continue
            </button>
          </div>
        </Modal>
      )}

      <Modal
        title=""
        open={isConfirmationModalOpen}
        onCancel={handleConfirmationCancel}
        footer={null}
        centered
        width={600}
        className="sm:w-auto w-full rounded-lg"
      >
        <div className="p-4 sm:p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer mr-3 hover:bg-green-800 hover:text-white w-10 h-10 p-3 rounded-md"
                onClick={() => {
                  window.history.back();
                  window.location.reload();
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              <div className="flex items-center gap-2">
                <img
                  src={
                    therapistDetails?.profilePictureUrl?.cdnUrl ||
                    therapistDetails?.profilePictureUrl?.s3Url ||
                    ProfilePicture
                  }
                  className="w-10 h-10 rounded-full"
                ></img>

                <span className="font-semibold text-base sm:text-lg">
                  {therapistDetails.givenName +
                    " " +
                    therapistDetails.familyName}
                </span>
              </div>
            </div>
          </div>

          <h3 className="text-lg sm:text-xl font-semibold mb-1">
            Individual Therapy Session
          </h3>
          <p className="text-[#154134] mb-4">{showmode} </p>

          <div className="flex items-center justify-between bg-gray-100 p-3 rounded-lg text-sm sm:text-base mb-4">
            <div className="flex items-center">
              <div className="flex flex-col items-center bg-white rounded-lg mr-3">
                <span className="px-3 py-1 bg-[#154134] rounded-t-lg font-semibold text-white sm:text-sm">
                  {formatDate(selectedDate)}
                </span>
                <span className="px-3 py-2 text-lg font-bold">
                  {formatDate(selectedDate).slice(4, 6)}
                </span>
              </div>
              <div>
                <p className="font-semibold">{formatDate(selectedDate)}</p>
                <p className=" text-[#154134] sm:text-sm">
                  {moment(selectedTime).format("hh:mm A")} (GMT +05:30)
                </p>
              </div>
            </div>
            <button
              className="border border-gray-300 rounded-full px-4 py-2 text-white sm:text-sm cursor-pointer bg-[#154134]"
              onClick={handleChange1}
            >
              Change
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-4 w-full mb-4">
            <div className="flex items-center gap-1 relative">
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => setIsEditingName(false)}
                className="bg-gray-100 w-full rounded-lg py-2 px-4 text-sm sm:text-base"
              />
              <button
                className="text-gray-600 absolute right-3 top-3"
                onClick={() => setIsEditingName(true)}
              >
                <img src={EditIcon} alt="edit" className="w-4 h-4" />
              </button>
            </div>

            {/* Editable Mobile Field */}
            <div className="flex items-center gap-1 relative">
              <Input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                onBlur={() => setIsEditingMobile(false)}
                className="bg-gray-100 w-full rounded-lg py-2 px-4 text-sm sm:text-base"
              />
              <button
                className="text-gray-600 absolute right-3 top-3"
                onClick={() => setIsEditingMobile(true)}
              >
                <img src={EditIcon} alt="edit" className="w-4 h-4" />
              </button>
            </div>
          </div>
          <h4 className="font-semibold text-sm sm:text-base mb-2">
            Order Summary
          </h4>
          <div className="flex items-center justify-between bg-gray-100 p-3 rounded-lg text-sm sm:text-base mb-6">
            <span>{selectedCard.title}</span>
            <span> ₹{price}</span>
          </div>
          {/* Payment Method Selection */}
          <div className="mb-4">
            <Radio.Group
              onChange={handlePaymentMethodChange}
              value={paymentMethod}
              className="w-full"
            >
              <div className="flex flex-col gap-2">
                <Radio value="online" className=" p-3 rounded-lg">
                  Pay Online
                </Radio>
                <Radio value="cash" className=" p-3 rounded-lg">
                  Cash on Delivery
                </Radio>
              </div>
            </Radio.Group>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className="bg-[#EBEBE1] text-black rounded-full py-2 px-6 text-sm sm:text-base mt-5">
              ₹{price}
            </p>
            <button
              type="button"
              className="bg-[#154134] text-white rounded-full py-2 px-6 text-sm sm:text-base w-[90%]"
              onClick={handleConfirmationCancel}
            >
              {confirmLoader ? (
                <Spin tip="Loading..." indicator={<LoadingOutlined spin style={{color: 'white'}}/>}/>
              ) : paymentMethod === "online" ? "Confirm and Pay" : "Confirm"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookingModal;
