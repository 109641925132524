import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const verifyPayment = async ( paymentData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/servicerequests/verifyPayment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentData),
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  const verificationResponse = await response.json();
  return verificationResponse;
};

const handleFailedPayment = async (paymentData, authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/servicerequests/handleFailedPayment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorization}`,
      },
      body: JSON.stringify(paymentData),
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  const result = await response.json();
  return result;
};
const createServiceRequest = async (data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/servicerequests/createServiceRequest`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return await response.json();
};
const getServiceRequestById = async ({ serviceRequestId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/servicerequests/getServiceRequest`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: serviceRequestId }),
    }
  );
  return await response.json();
};
export {
  verifyPayment,
  handleFailedPayment,
  createServiceRequest,
  getServiceRequestById,
};
