import React, {useEffect, useState} from "react";
import PracflowLogo from "../assets/images/PracflowLogo.svg";
import Navbar from "../components/Profile/Layout/TherapistNavbar";
import HeroSection from "../components/Profile/TherapistHero";
import AboutMeSection from "../components/Profile/TherapistAbout";
import ServicesSection from "../components/Profile/TherapistServices";
import Testimonials from "../components/Profile/TherapistTestimonials";
import FAQSection from "../components/Profile/TherapistFAQ";
import ResourcesSection from "../components/Profile/TherapistResources";
import CTASection from "../components/Profile/TherapistAboveFooterCTA";
import Footer from "../components/Profile/Layout/TherapistFooter";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
// import HeroSection from './components/HeroSection';
// import ServicesSection from './components/ServicesSection';
// import AboutSection from './components/AboutSection';
// import BlogSection from './components/BlogSection';
// import ContactSection from './components/ContactSection';
// import Footer from './components/Footer';
import {
  fetchTherapistByHandle,
  fetchTherapistById,
} from "../Network/Therapist";
import {fetchUserDetails} from "../Network/Client";

import {handleError} from "../ErrorHandling/handleError";

const TherapistProfilePage = ({therapistProfile}) => {
  let {therapistId} = useParams();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [therapistDetails, setTherapistDetails] = useState({});
  const [userDetails, setUserDetails] = useState();
  const authorization = useSelector(
    (state) => state?.combined?.auth?.authorization
  );
  const fetchtherapist = async () => {
    try {
      let therapist;
      therapist = await fetchTherapistByHandle(therapistId);
      setTherapistDetails(therapist);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setisLoading(false);
    }
  };
  const fetchUser = async () => {
    try {
      const allDetails = await fetchUserDetails(authorization);
      setUserDetails(allDetails);
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  useEffect(() => {
    fetchtherapist();
    // console.log("Got it", therapistDetails, userDetails);
    if (authorization) {
      fetchUser();
    }
  }, [authorization, therapistId]);
  return (
    <div
      className="min-h-screen flex flex-col"
      // style={{ backgroundColor: theme.backgroundColor }}
    >
      {/* Navigation Bar */}
      <section className="bg-[#DBF5F2]">
        <Navbar therapistDetails={therapistDetails}/>
        {<HeroSection therapistDetails={therapistDetails}/>}
      </section>
      {<AboutMeSection therapistDetails={therapistDetails}/>}
      {/* <ServicesSection /> */}
      <ServicesSection therapistDetails={therapistDetails}/>
      {/* <ResourcesSection /> */}

      {/* <Testimonials /> */}
      <FAQSection therapistDetails={therapistDetails}/>
      <CTASection therapistDetails={therapistDetails}/>

      {/* Footer */}
      <Footer therapistDetails={therapistDetails}/>
      <div className="w-full py-1 text-center border-t-2 border-t-black bg-gray-100">
          <p><a href="https://pracflow.ai" className="text-black">Powered by pracflow.ai</a></p>
      </div>
    </div>
  );
};

export default TherapistProfilePage;
