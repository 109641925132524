import React, { useState } from "react";
import { Modal, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import StarIcon from "../../assets/images/Star.svg";
import "../Profile/Styles/BookingStyles.css";

const EventBookingModal = () => {
  const [isInitialModalOpen, setIsInitialModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [eventName] = useState("How to handle Stress : A Complete Guide");
  const [price] = useState("₹500");
  const [authorName] = useState("Arjun Gupta");
  const [rating] = useState(4.5);
  const [eventDate] = useState("15th Nov, 2024");
  const [platform] = useState("Google Meet");
  const [duration] = useState("2hrs");

  const showInitialModal = () => {
    setIsInitialModalOpen(true);
  };

  const handleContinue = () => {
    setIsInitialModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showInitialModal}>
        Book Event
      </Button>

      {/* Initial Event Booking Modal */}
      <Modal
        title=""
        open={isInitialModalOpen}
        onCancel={() => setIsInitialModalOpen(false)}
        footer={null}
        centered
        width={600}
      >
        <div className="">
          {/* Header with Back Icon, Profile, and Rating */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex gap-4">
              <ArrowLeftOutlined
                className="text-lg"
                onClick={() => setIsInitialModalOpen(false)}
              />
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-gray-200"></div>{" "}
                {/* Placeholder for profile image */}
                <span className="font-semibold text-lg">{authorName}</span>
              </div>
            </div>
            <div className="flex items-center border border-black rounded-full px-4 py-2">
              <span className="text-sm mr-1">{rating}</span>
              <img src={StarIcon} alt="star" className="w-4 h-4" />
            </div>
          </div>

          {/* Event Information */}
          <h3 className="text-xl font-bold mt-2 mb-1">{eventName}</h3>
          <p className="text-black font-bold mb-4">
            {eventDate} | {platform} | {duration}
          </p>

          {/* Event Image */}
          <div className="mb-4">
            <img
              src="https://i.ibb.co/gTp4Lck/unsplash-Csv5y4-VYm0w.png"
              alt="Event"
              className="w-full h-3/4 rounded-lg"
            />
          </div>

          {/* Event Description */}
          <h4 className="font-semibold mb-2">Event Details</h4>
          <p className="text-gray-600 mb-4">
            Join us for a comprehensive 2-hour session on managing stress
            effectively. This event will guide you through practical techniques
            and strategies to handle stress in both academic and personal
            settings. You'll be interacting with fellow students and
            professionals, sharing insights and learning how to build resilience
            in stressful situations. Perfect for anyone looking to develop a
            healthier mindset and gain tools for stress management!
          </p>

          {/* Continue Button */}
          <button
            type="primary"
            className="w-full rounded-full py-2 bg-[#154134] text-white"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        title=""
        open={isConfirmationModalOpen}
        onCancel={handleConfirmationCancel}
        footer={null}
        centered
        width={600}
      >
        <div className="">
          {/* Header with Back Icon, Profile, and Rating */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex gap-4">
              <ArrowLeftOutlined
                className="text-lg"
                onClick={handleConfirmationCancel}
              />
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-gray-200"></div>{" "}
                {/* Placeholder for profile image */}
                <span className="font-semibold text-lg">{authorName}</span>
              </div>
            </div>
            <div className="flex items-center border border-black rounded-full px-4 py-2">
              <span className="text-sm mr-1">{rating}</span>
              <img src={StarIcon} alt="star" className="w-4 h-4" />
            </div>
          </div>

          {/* Event Summary */}
          <h3 className="text-xl font-bold mt-2 mb-1">{eventName}</h3>
          <p className="text-gray-500 mb-4">Event Booking | {price}</p>

          {/* Order Summary */}
          <div className="w-full mb-4">
            <h4 className="font-bold">Order Summary</h4>
            <div className="bg-gray-100 p-4 rounded-lg flex justify-between">
              <span>{eventName}</span>
              <span>{price}</span>
            </div>
          </div>

          {/* Total Price and Confirm Button */}
          <div className="w-full flex justify-between items-center">
            <span className="text-md mr-3 py-2 px-4 rounded-full bg-[#EBEBE1]">
              ₹500
            </span>

            <button
              type="primary"
              className="bg-[#154134] py-2 text-white rounded-full w-full px-5 text-white"
            >
              Confirm and Pay
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventBookingModal;
