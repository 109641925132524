import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  StarFilled,
  CalendarOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Card, Button, Avatar } from "antd";
import BookingBg from "../../assets/images/BookingBg.png";
import { useParams } from "react-router-dom";
import { getServicesForClients } from "../../Network/Service";
import ConfirmationModal from "./TherapistConfirmationModal";
import WorkshopPicture from "../../assets/images/workshop.jpg";
import ProfilePicture from "../../assets/images/TherapistDummyImage.png";
const Workshop = ({ selectedCard, therapistDetails }) => {
  const { id } = useParams();
  const [price, setPrice] = useState(selectedCard?.baseRate);
  const [showNextComponent, setShowNextComponent] = useState(false);

  // Calculate the step values
  const minRate = selectedCard.baseRate;
  const maxRate = selectedCard.maxRate;
  const step = 100;
  const stepValues = [];
  for (let value = minRate; value <= maxRate; value += step) {
    stepValues.push(value);
  }

  useEffect(() => {
    const fetchServiceById = async () => {
      await getServicesForClients(id);
    };
    fetchServiceById();
  }, [id]);

  const handleRangeChange = (e) => {
    const inputValue = parseInt(e.target.value);
    setPrice(inputValue);
  };

  const handleContinueClick = () => {
    setShowNextComponent(true);
  };
  const percentage = ((price - minRate) / (maxRate - minRate)) * 100;

  return (
    <div
      style={{
        backgroundImage: `url(${BookingBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full h-screen flex items-center justify-center px-4 sm:px-0"
    >
      {!showNextComponent ? (
        <div className="max-w-2xl mx-auto bg-white  h-auto rounded-2xl w-full">
          <header className="flex items-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6 cursor-pointer mr-3 hover:bg-green-800 hover:text-white w-10 h-10 p-3 rounded-md"
              onClick={() => {
                window.history.back();
                window.location.reload();
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>

            <div className="flex-1 flex items-center ml-2">
              <img
                src={
                  therapistDetails?.profilePictureUrl?.cdnUrl ||
                  therapistDetails?.profilePictureUrl?.s3Url ||
                  ProfilePicture
                }
                className="w-10 h-10 rounded-full"
                alt="Therapist Profile"
              />

              <span className="text-lg font-medium text-black ml-2">
                {therapistDetails?.givenName +
                  " " +
                  therapistDetails?.familyName}
              </span>
              <div className="flex items-center ml-auto">
                <span className="bg-green-800 text-white px-3 py-1 rounded-lg flex items-center">
                  ₹{" "}
                  <input
                    type="text"
                    value={price}
                    onChange={(e) => {
                      const value = e.target.value;
                    }}
                    onBlur={() => {
                      let validatedPrice = parseInt(price);
                      if (isNaN(validatedPrice) || validatedPrice < minRate) {
                        validatedPrice = minRate;
                      } else if (validatedPrice > maxRate) {
                        validatedPrice = maxRate;
                      }
                      setPrice(validatedPrice);
                    }}
                    className="bg-transparent text-white border-none w-16 text-right outline-none"
                  />
                </span>
              </div>
            </div>
          </header>

          {selectedCard.isFlexibleRate && (
            <div className="px-9 pb-4 relative">
              <div className="relative ">
                <input
                  type="range"
                  min={minRate}
                  max={maxRate}
                  value={price}
                  onChange={handleRangeChange}
                  className="w-full range-input appearance-none h-2 bg-gray-200 rounded-full"
                  style={{
                    background: `linear-gradient(to right, 
                      #4CAF50 0%, 
                      #4CAF50 ${percentage}%, 
                      #E0E0E0 ${percentage}%, 
                      #E0E0E0 100%)`,
                  }}
                />
                <div
                  className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 bg-green-800 text-white text-sm px-2 py-1 rounded"
                  style={{
                    left: `calc(${percentage}% - 10px)`,
                  }}
                >
                  ₹{price}
                </div>
                <div className="flex justify-between text-xs text-gray-500 mt-2">
                  {stepValues.map((value, index) => (
                    <span
                      key={index}
                      className={`${
                        Number(price) === value
                          ? "font-bold text-green-800"
                          : ""
                      }`}
                    >
                      ₹{value}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Rest of the component remains unchanged */}
          <main className="p-4">
            <h1 className="text-2xl font-bold mb-4">{selectedCard.title}</h1>
            {/* Image Card */}
            <Card className="mb-6" bodyStyle={{ padding: 0 }} bordered={false}>
              <img
                src={WorkshopPicture}
                alt="Lecture session"
                className="w-full h-48 object-cover"
              />
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center bg-slate-200 px-3 py-2 rounded-full font-semibold">
                    <CalendarOutlined className="mr-2 text-gray-600" />
                    <span className="text-sm">
                      {new Date(
                        selectedCard?.workshop?.workshopTimings[0].workshopDate
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  <div className="flex items-center bg-slate-200 px-3 py-2 rounded-full font-semibold">
                    <VideoCameraOutlined className="mr-2 text-gray-600" />
                    <span className="text-sm">Google Meet</span>
                  </div>
                  <span className="text-sm  bg-slate-200 px-3 py-2 rounded-full font-semibold">
                    {Math.abs(
                      (new Date(
                        selectedCard?.workshop?.workshopTimings[0].endTime
                      ) -
                        new Date(
                          selectedCard?.workshop?.workshopTimings[0].startTime
                        )) /
                        (1000 * 60 * 60)
                    ).toFixed(1)}{" "}
                    hrs
                  </span>
                </div>
              </div>
            </Card>

            {/* Event Details */}
            <Card
              bordered={false}
              title={
                <span className="font-bold text-[16px]">Event Details</span>
              }
              className="mb-6"
            >
              <p className="text-black">{selectedCard.description}</p>
            </Card>
          </main>
          <div className="p-4">
            <button
              type="primary"
              className="w-full rounded-lg border-2 px-2 py-2 bg-green-800 text-white hover:bg-green-700"
              onClick={handleContinueClick}
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <ConfirmationModal
          price={price}
          selectedCard={selectedCard}
          therapistDetails={therapistDetails}
        />
      )}
    </div>
  );
};

export default Workshop;
