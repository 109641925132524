import React, { useState } from "react";
import DownArrowIcon from "../../assets/images/DownArrowBlack.svg";
import FAQData from "../../assets/data/TherapistData/faq.json";
// FAQ Section
const FAQSection = ({ therapistDetails }) => {
  const faqs = therapistDetails.faq?.length > 0 ? therapistDetails.faq : FAQData.faqs;
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index); // Toggle active FAQ
  };

  if (!faqs || faqs.length === 0) return null; // Don't render if there are no FAQs

  return (
    <section className="py-8 px-4 bg-white lg:px-10" id="FAQ">
      <h2 className="text-xl sm:text-3xl kalnia-normal text-left mb-8">
        Frequently Asked Questions
      </h2>
      <div className="">
        {therapistDetails?.length > 0 ||
          faqs.map((faq, index) => (
            <div key={faq.id} className="bg-gray-50 rounded-xl mb-2">
              <button
                className="w-full text-left py-4 text-lg poppins-normal font-semibold focus:outline-none flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                {activeFAQ === index ? (
                  <img src={DownArrowIcon} alt="arrow" className="rotate-180" />
                ) : (
                  <img src={DownArrowIcon} alt="arrow" />
                )}
              </button>
              {activeFAQ === index && (
                <div className="pl-4 pb-4">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
      </div>
    </section>
  );
};

export default FAQSection;
