import {MapPinIcon} from "@heroicons/react/24/outline";
import React from "react";
import AboutData from "../../assets/data/TherapistData/About.json";
import { Avatar, Spin } from "antd";
import { CaretLeftOutlined, UserOutlined } from "@ant-design/icons";
import TherapistPicture from "../../assets/images/TherapistDummyImage.png";

const AboutMeSection = ({ aboutMe, theme, therapistDetails }) => {
  const {
    availability,
  } = AboutData.aboutMe;

  const {
    fontFamilyPrimary,
    fontFamilySecondary,
  } = AboutData.theme;

  return (
    <section className="pt-5 lg:py-12 px-4 lg:px-8" id="about">
      <h2
        className="text-3xl lg:text-4xl kalnia-normal mb-8"
        style={{ fontFamily: fontFamilyPrimary }}
      >
        About me
      </h2>

      <div className="flex flex-col lg:flex-col items-center lg:items-start bg-gray-50 p-6 lg:p-0 rounded-lg">
        {/* Profile Image */}
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/5 w-full">
            <img
              src={
                therapistDetails.profilePictureUrl?.cdnUrl ||
                therapistDetails?.profilePictureUrl?.s3Url ||
                TherapistPicture
              }
              alt="Therapist"
              className="h-full w-full rounded-3xl object-cover"
            ></img>
          </div>
          {/* Details */}
          <div className="poppins-normal lg:w-full sm:ml-5 mt-8 lg:mt-0 ">
            {/* Name and availability */}
            <div className="flex items-center gap-2  flex-wrap justify-between">
              <h3 className="poppins-normal text-2xl font-semibold">
                {" "}
                {therapistDetails?.givenName || ""}{" "}
                {therapistDetails?.familyName || ""}
              </h3>
              {availability && (
                <span className="py-1 text-[#2C7CF3] bg-[#F0F8FF] font-semibold  text-sm rounded-full">
                  {availability.text}
                </span>
              )}
            </div>

            {/* Experience and qualification */}
            {therapistDetails.experience && (
              <p className="mt-2 lg:mt-0 text-lg text-gray-600 font-medium">
                {therapistDetails?.experience
                  ? `${therapistDetails.experience} Years Experience`
                  : ""}
                &bull; {therapistDetails?.degree || ""}
              </p>
            )}

            {/* Description */}
            <div className="lg:text-left text-justify">
            <p className="mt-4 text-lg text-gray-700 leading-relaxed ">
              {therapistDetails?.profileDescription}
            </p>
            </div>

            {/*/!* Clinic Info *!/*/}
            {therapistDetails.businessAddress && (
              <div className="mt-6 flex items-center text-gray-600">
                <MapPinIcon className="h-5 w-auto"></MapPinIcon>
                <p
                  className="mb-0"
                  style={{ fontFamily: fontFamilySecondary }}
                >
                  {`${therapistDetails.businessAddress.city}, ${therapistDetails.businessAddress.state}, ${therapistDetails.businessAddress.country}`}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          {/* Specializations */}
          {therapistDetails?.specialty?.length > 0 && (
            <div className="mt-6 items-center flex flex-wrap gap-2">
              <h4 className="text-xl font-medium kalnia-normal m-0 mt-2 p-0">
                Specializations
              </h4>
              <div className="flex flex-wrap mt-2 lg:mt-0 items-center gap-2">
                {/* {therapistDetails?.specialty || ''} */}

                {therapistDetails?.specialty?.map((specialization, idx) => (
                  <span
                    key={idx}
                    className="flex text-lg items-center gap-2 tracking-2 px-4 py-1 rounded-full bg-green-50 text-green-800"
                    style={{ fontFamily: fontFamilySecondary }}
                  >
                    <span>✱</span>
                    <span>{specialization}</span>
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Languages */}
          {therapistDetails?.languages?.length > 0 && (
            <div className="mt-6 flex item-center flex-wrap gap-2">
              <h4 className="text-xl font-medium kalnia-normal m-0 mt-2 p-0">
                Languages
              </h4>
              <div className="flex flex-wrap mt-2 lg:mt-0 gap-2">
                {therapistDetails?.languages?.map((language, idx) => (
                  <span
                    key={idx}
                    className="flex items-center tracking-2 gap-2 text-lg px-4 py-1 rounded-full bg-blue-50 text-blue-800 "
                  >
                    <span>✱</span>
                    <span>{language}</span>
                  </span>
                )) || ""}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutMeSection;
