import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { getServiceRequestById } from "../Network/Payment";
import {
  fetchTherapistById,
  fetchTherapistContactDetails,
} from "../Network/Therapist";
import PracflowLogo from "../assets/images/PracflowLogo.svg";

const { Title, Text } = Typography;
const razorPayStatusMap = {
  'captured': 'completed',
  'authorized': 'pending',
  'failed': 'failed',
  'refunded': 'refunded',
  'created': 'pending',
};

const paymentStatusMapInternal = {
  pending: 'Pending',
  completed: 'Completed',
  failed: 'Failed',
  refunded: 'Refunded',
  cash: 'Pending'
}

const PaymentPendingStatus = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serviceRequest, setServiceRequest] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusMapInternal.pending);
  const handleSendWhatsApp = () => {
    if (serviceRequest && PhoneNumber) {
      const {
        clientName,
        clientNumber,
        category,
        originalPrice,
        discountCode,
        totalDiscountApplied,
        finalPrice,
        paymentMode,
        appointmentDate,
        appointmentTime,
      } = serviceRequest?.serviceRequest;
      const { title } = serviceRequest?.serviceDetails;
      const checkoutUrl = `${window.location.origin}/checkout/${id}`;
      let message =
        `New Request\n*Client Details*: ${clientName} ${
          clientNumber?.isdCode + clientNumber?.phone
        }\n` +
        `*Service*: ${title}\n` +
        `*Service Category*: ${category}\n` +
        `*Original Price*: ${originalPrice}\n`;

      if (totalDiscountApplied > 0) {
        message +=
          `*Discount Code*: ${discountCode}\n` +
          `*"Total Discount"*: ${totalDiscountApplied}\n`;
      }

      message +=
        `*Total Amount Payable*: ${finalPrice}\n` +
        `*Payment Mode*: ${paymentMode}\n` +
        `*Payment status*: ${paymentStatus}\n`;

      if (paymentMode === "cash" || paymentStatus !== paymentStatusMapInternal.completed) {
        message += `*Total Amount Pending*: ${finalPrice}\n`
      } else {
        message += `*Total Amount Pending*: 0\n`;
      }
      if (paymentMode !== "cash") {
        message += `*Payment Method*: ${serviceRequest?.paymentDetails?.paymentMethod}\n`
        message += `*Payment Id*: ${serviceRequest?.paymentDetails?.paymentId}\n`
      }

      if (category === "appointment") {
        message += `*Schedule*: ${moment(appointmentTime).format('DD/MM/YYYY hh:mm A')}\n`;
      }

      message +=
        `See invoice: ${checkoutUrl}\n\n` + `Kindly confirm the service by replying to the message.`;

      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/${PhoneNumber}?text=${encodedMessage}`;

      window.open(whatsappUrl, "_blank");
    } else {
      console.log("Service request details or phone number are not available.");
    }
  };

  const handleOrderDetails = () => {
    //const currentDomain = new URL(window.location.href);
    const orderDetailsUrl = `${window.location.origin}/checkout/${id}`;
    window.location.href = orderDetailsUrl;
  };

  useEffect(() => {
    const FetchInfo = async () => {
      const response = await getServiceRequestById({
        serviceRequestId: id,
      });
      setServiceRequest(response);
      setPhoneNumber(
        response?.therapistContactDetails?.phoneNumber?.isdCode +
          response?.therapistContactDetails?.phoneNumber?.phone
      );
      if (response.serviceRequest.paymentId &&
        response.paymentDetails?.captured === true &&
        response.paymentDetails?.paymentStatus==='captured') {
        setPaymentStatus(paymentStatusMapInternal.completed)
      } else if (response.serviceRequest.paymentId) {
        setPaymentStatus(`${paymentStatusMapInternal[razorPayStatusMap[response.paymentDetails.paymentStatus]]}`)
      }
    };
    FetchInfo();
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-blue-300 p-4 flex-col">
      <div className="w-full max-w-sm bg-white rounded-lg shadow-2xl">
        <div className="p-6">
          <div className="text-center space-y-2">
            <Title level={4} className="!mt-0 !mb-2 font-medium">
              {`Payment is ${paymentStatus}`}
            </Title>
            <Text className="text-sm">
              Send a message to confirm the order status
            </Text>
          </div>
        </div>

        {/* Footer with Buttons */}
        <div className="p-4 bg-gray-100 space-y-2">
          <Button
            type="primary"
            icon={<WhatsAppOutlined />}
            size="large"
            className="h-11 w-full flex items-center justify-center gap-2 !bg-[#acc8a2] hover:!bg-[#128C7E] transition-colors"
            onClick={handleSendWhatsApp}
          >
            Send WhatsApp to Confirm
          </Button>
          <Button
            type="default"
            size="large"
            className="h-11 w-full flex items-center justify-center gap-2 !bg-[#ecdcc9] hover:!bg-[#128C7E] transition-colors"
            onClick={handleOrderDetails}
          >
            Check Order Details
          </Button>
        </div>
      </div>
      <div className="mt-10 flex flex-col">
        <span className="ml-2 text-xs font-bold" style={{ color: "#270949" }}>
          Powered by
        </span>
        <img
          className="mx-auto w-auto bg-transparent"
          style={{ height: "50px", width: "auto" }}
          src={PracflowLogo}
          alt="Your Company"
        />
      </div>
    </div>
  );
};

export default PaymentPendingStatus;
